<template>
  <v-card class="mb-1 mr-1">
    <v-card-text>
      <v-row class="px-4">
        <v-col>
          <v-avatar
              color="bg-warning lighten-2 border-radius-xl"
              class="shadow-warning"
              height="84"
              width="84"
          >
            <v-icon class="material-icons-round text-white" size="28" v-if="titolo==='fatturato_totale'">
              mdi-currency-eur
            </v-icon>
            <v-icon class="material-icons-round text-white" size="28" v-else-if="titolo==='bottiglie_scaricate'">
              mdi-glass-wine
            </v-icon>
            <v-icon class="material-icons-round text-white" size="28" v-else-if="titolo==='giorni_lavorati'">
              mdi-account-hard-hat-outline
            </v-icon>
            <v-icon class="material-icons-round text-white" size="28" v-else-if="titolo==='media_bottiglie_giornaliera'">
              mdi-bottle-wine
            </v-icon>
            <v-icon class="material-icons-round text-white" size="28" v-else-if="titolo==='media_percentuale_costo_vino'">
              mdi-percent-outline
            </v-icon>
            <v-icon class="material-icons-round text-white" size="28" v-else>
              mdi-ab-testing
            </v-icon>
          </v-avatar>
        </v-col>
        <v-col class="">
          <div class="text-md gray--text">
            {{ toTitleCase(titolo) }}
          </div>
          <div>
            <h3 class="text-h3 text-typo font-weight-bolder" v-if="titolo==='fatturato_totale'">
              {{ numero | toCurrency }}
            </h3>
            <h3 class="text-h3 text-typo font-weight-bolder" v-else-if="titolo==='giorni_lavorati'">
            {{ numero }}
            </h3>
            <h3 class="text-h3 text-typo font-weight-bolder" v-else-if="titolo==='bottiglie_scaricate'">
              {{ numero }}
            </h3>
            <h3 class="text-h3 text-typo font-weight-bolder" v-else-if="titolo==='media_bottiglie_giornaliera'">
              {{ numero }}
            </h3>
            <h3 class="text-h3 text-typo font-weight-bolder" v-else-if="titolo==='media_percentuale_costo_vino'">
              {{ numero }}%
            </h3>
            <h3 class="text-h3 text-typo font-weight-bolder" v-else>
              {{ numero | toCurrency }}
            </h3>
          </div>
        </v-col>
      </v-row>
    </v-card-text>
    <hr class="dark horizontal mt-3 mb-4"/>
  </v-card>
</template>

<script>
export default {
  name: "CardReports",
  props: {
    color: String,
    icon: String,
    titolo: [Number, String],
    numero: Number
  },
  data() {
    return {
      title: "",
      cifra: 0,
    }
  },
  methods: {
    toTitleCase(str) {
    return str
        .split('_') // Divide la stringa in parole separate da underscore
        .map(word => word.charAt(0).toUpperCase() + word.slice(1)) // Capitalizza la prima lettera di ogni parola
        .join(' '); // Unisce le parole con uno spazio
}
  }
}
</script>

<style scoped>

</style>
