<template>
  <v-app-bar
    :color="background"
    height="auto"
    class="mt-6 py-2 px-0 mx-6 border-radius-xl toolbar-content-padding-y-none"
    :class="navbarFixed ? '' : 'position-sticky blur shadow-blur z-index-sticky py-2'"
    flat
  >
    <v-row>
      <v-col cols="12" sm="6" class="d-flex">
        <div
          class="drawer-toggler pa-5 cursor-pointer"
          :class="{ active: togglerActive }"
          @click="minifyDrawer"
          v-if="!$vuetify.breakpoint.mobile"
        >
          <div class="drawer-toggler-inner">
            <i class="drawer-toggler-line bg-body"></i>
            <i class="drawer-toggler-line bg-body"></i>
            <i class="drawer-toggler-line bg-body"></i>
          </div>
        </div>
        <div class="mt-auto mb-auto">
          <v-breadcrumbs class="pb-0 pt-1 px-0">
            <v-breadcrumbs-item
              to="/"
              active-class="active-breadcrumb"
              class="opacity-5 text-dark"
            >
              <v-icon
                small
                color="orange darken-2"
              >
                home
              </v-icon>
            </v-breadcrumbs-item>
            <v-breadcrumbs-item class="opacity-5 text-dark">
              {{ $route.meta.groupName }}
            </v-breadcrumbs-item>
            <li class="v-breadcrumbs__divider opacity-5 px-2 text-muted">/</li>
            <v-breadcrumbs-item
              active-class="active-breadcrumb"
              class="no-default-hover text-dark"
            >
              <template v-if="$route.name === 'Dashboard'">Default</template>
              <template v-else>{{ $route.name }}</template>
            </v-breadcrumbs-item>
          </v-breadcrumbs>
        </div>
      </v-col>
      <v-col
        cols="12"
        sm="6"
        class="d-flex align-center"
      >
<!--        Ricerca --->
         <v-form
          v-if="hasBg"
          @submit.prevent="submit"
          class="navbar-search navbar-search-light d-inline-block ms-auto"
          id="navbar-search-main"
        >
        </v-form>
        <!--        Icona Utente --->
        <!--        Icona Settings --->
        <v-btn
          elevation="0"
          :ripple="false"
          height="43"
          class="
            font-weight-600
            text-capitalize
            drawer-toggler
            py-3
            px-0
            rounded-sm
          "
          :class="{
            'btn-dark-toggler-hover': !hasBg,
            'btn-toggler-hover': hasBg,
            active: togglerActive,
          }"
          v-if="$vuetify.breakpoint.mobile"
          color="transparent"
          @click="drawerClose"
        >
          <div class="drawer-toggler-inner">
            <i class="drawer-toggler-line text-body"></i>
            <i class="drawer-toggler-line text-body"></i>
            <i class="drawer-toggler-line text-body"></i>
          </div>
        </v-btn>
        <!--        Icona Notifiche --->
        <v-btn
          icon
          :ripple="false"
          :class="{ 'btn-dark-hover': !hasBg, 'btn-hover': hasBg }"
          class="text-body px-5"
          :color="linkColor"
          small
          @click="$emit('toggleSettingsDrawer', true)"
        >
          <v-icon class="material-icons-round" size="20"> settings </v-icon>
        </v-btn>
        <v-menu
          transition="slide-y-transition"
          offset-y
          offset-x
          min-width="300"
          max-width="300"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              icon
              :ripple="false"
              :class="{ 'btn-dark-hover': !hasBg, 'btn-hover': hasBg }"
              class="text-body"
              v-bind="attrs"
              v-on="on"
              small
            >
              <v-avatar size="30" class="my-3 ms-2">
                <img src="@/assets/img/team-3.jpg" alt="Brooklyn" />
              </v-avatar>
<!--              <v-badge-->
<!--                color="#f44335"-->
<!--                content=""-->
<!--                offset-x="1"-->
<!--                offset-y="0"-->
<!--                class="position-absolute"-->
<!--                bordered-->
<!--              >-->
<!--              </v-badge>-->
            </v-btn>
          </template>
          <v-list class="pa-3">
            <v-list-item
              v-for="(item, i) in dropdown"
              :key="i"
              class="
                pa-4
                list-item-hover-active
                d-flex
                align-items-center
                py-1
                my-1
                border-radius-md
              "
            >
              <v-icon class="material-icons-round text-body" size="20">{{item.icon}}</v-icon>

              <v-list-item-content class="pa-0">
                <v-list-item-title
                  :class="item.title === 'LogOut' ? 'text--warning': ''"
                  class="text-body-2 ls-0 text-typo font-weight-600 mb-0"
                  @click="logout(item.title)"
                >
                  <v-row>
                    <v-col>
                      <h6
                        class="text-sm font-weight-normal ms-2 text-typo"
                        v-html="item.title"
                      ></h6>
                    </v-col>
                  </v-row>
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-menu>
      </v-col>
    </v-row>
  </v-app-bar>
</template>
<script>
export default {
  name: "app-bar",
  props: {
    background: String,
    hasBg: Boolean,
    linkColor: String,
    toggleActive: Boolean,
    navbarFixed: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      drawer: false,
      togglerActive: false,
      dropdown: [
        {
          icon: "logout",
          title: "LogOut",
        },
      ],
    };
  },
  mounted() {

  },
  methods: {
    drawerClose() {
      this.togglerActive = !this.togglerActive;
      this.$emit("drawer-toggle", true);
    },
    minifyDrawer() {
      this.togglerActive = !this.togglerActive;
      this.mini = !this.mini;
      const body = document.getElementsByTagName("body")[0];

      if (body.classList.contains("drawer-mini")) {
        body.classList.remove("drawer-mini");
      } else {
        body.classList.add("drawer-mini");
      }
    },
      logout(title) {
          if (title === "LogOut") {
              this.$store.dispatch('auth/logout').then(() => {
                      this.$router.push('/login')
                  }
              ).then(() => {
                  this.$dialog.message.success('Logout Corretto', {
                      type: 'primary',
                      rounded: true,
                      position: 'top-left',
                      color: "green accent-5",
                      outlined: false,
                  })
              })
          }

      }
  },
  watch: {
    toggleActive(val) {
      this.togglerActive = val;
    },
  },
};
</script>
