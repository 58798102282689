import axios from "axios";
import authHeader from "@/services/auth.header";
const API_URL = process.env.VUE_APP_API_URL;


class warehouseService {
  // Warehouse
  getMagazzini(params) {
    return axios.get(API_URL + 'warehouse/', { headers: authHeader(), params: params })
  }
  async deleteWarehouse(id) {
    return await  axios.delete(API_URL + 'warehouse/' + id + '/', { headers: authHeader() })
  }
  editWarehouse(item) {
    return axios.put(API_URL + 'warehouse/' + item.id + '/', item, { headers : authHeader() })
  }
  addWarehouse(payload) {
    return axios.post(API_URL + 'warehouse/', payload, { headers:authHeader()})
  }
  // Rack
  saveRack(params) {
    return axios.post(API_URL + 'racks/', params, {
      headers: authHeader(),
    })
  }
  editRack(params) {
    return axios.put(API_URL + 'racks/' + params.id + '/', params,  { headers:authHeader()})
  }
  getRacks(params) {
    return axios.get(API_URL + 'racks/', {
      headers: authHeader(), params:params
    })
  }
  getRack(id) {
    return axios.get(API_URL + 'racks/' + id + '/', {
      headers: authHeader()
    })
  }
  filterRackById(params) {
    return axios.get(API_URL + 'racks', {headers: authHeader(), params:params})
  }
  getCellRack(params) {
    return axios.get(API_URL + 'cell_rack', { headers: authHeader(), params:params})
  }
  updateRack(params) {
    return axios.put(API_URL + 'racks/' + params.id + '/',  params, {
      headers: authHeader(),
    })
  }
  deleteRack(id) {
    return axios.delete(API_URL + 'racks/' + id + '/', { headers: authHeader()})
  }
  removeBottleFromRackCell(payload) {
    return axios.delete(API_URL + `warehouse_items/${payload.id}/`, { headers: authHeader()})
  }
  moveWarehouseItem(payload) {
    return axios.post(API_URL + 'warehouse_items/move_wine/', payload, { headers: authHeader()})
  }
  getWarehouseItems(params) {
    return axios.get(API_URL + 'warehouse_items/', { headers: authHeader(), params:params})
  }
  editWarehouseItem(params) {
    return axios.patch(API_URL + `warehouse_items/${params.id}/`, params, { headers: authHeader() })
  }
  quickEditWarehouseItemQuantity(params){
    return axios.put(API_URL + `warehouse_items/quick_edit_quantity/`, params, {headers:authHeader()})
  }
  async fetchSnapshot(params){
    return await axios.get(API_URL + 'snapshot/', { headers:authHeader(), params:params })
  }
  async createSnapshot(params) {
    return await axios.post(API_URL + "warehouse/create_snapshot/",{}, {
      headers: authHeader(),
      params:params
    })
  }
  async deleteSnapshot(id) {
    return await axios.delete(API_URL + `snapshot/${id}/`, { headers: authHeader()})
  }
  async downloadExcel(id) {
    return await axios.get(API_URL + `snapshot/${id}/excel/`, {
      headers: authHeader(), responseType: "blob"
    })
  }
  async getBistrots(params) {
    return await axios.get(API_URL + `bistrots/?by_data=${params.data}&page_size=300`, {headers:authHeader(), params:params})
  }
  async getSimpleBistro(params) {
    return await axios.get(API_URL + 'bistrots', {headers:authHeader(), params:params})
  }
  async getWarehouseAnalytics(id, params) {
    return await axios.get(API_URL + `warehouse_analytics/?id=${id}`,{ headers: authHeader(), params:params})
  }
  async editBistrot(payload) {
    return await axios.put(API_URL + `bistrots/${payload.id}/`, payload, { headers: authHeader()})
  }
}

export default new warehouseService()
