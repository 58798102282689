<template>
  <v-navigation-drawer
      width="80%"
      height="calc(100% - 2rem)"
      fixed
      app
      floating
      :expand-on-hover="mini"
      :value="drawer"
      :right="$vuetify.rtl"
      class="my-4 ms-2 border-radius-xl"
      :class="!$vuetify.breakpoint.mobile ? '' : 'bg-white'"
      :data-color="sidebarColor"
      :data-theme="sidebarTheme"
  >
    <v-list-item class="pa-0">
      <v-list-item-content class="pa-0">
        <v-list-item-title class="title d-flex mb-0">
          <div class="v-navigation-drawer-brand pa-4 align-center">
            <h2>VinoVero</h2>
            <h3 class="font-weight-bolder text-orange">
              {{ this.$store.state.auth.user.store_name }}
            </h3>
            Email: <small>{{ this.$store.state.auth.user.email }}</small>
          </div>
        </v-list-item-title>

      </v-list-item-content>
    </v-list-item>

    <hr
        class="horizontal mb-0"
        :class="sidebarTheme === 'dark' ? 'light' : 'dark'"
    />

    <v-list nav dense>
      <v-list-group
          :ripple="false"
          v-for="item in items"
          :key="item.title"
          v-model="item.active"
          append-icon="fas fa-angle-down"
          class="mx-2 cursor-pointer"
          active-class="item-active"
      >
        <template v-slot:activator>
          <v-list-item-icon class="me-2 align-center">
            <i class="material-icons-round opacity-10">{{ item.action }}</i>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title
                class="ms-1"
            >{{ item.title }}
            </v-list-item-title>
          </v-list-item-content>
        </template>

        <v-list-item
            :ripple="false"
            link
            class="mb-1 no-default-hover px-0"
            :class="child.items ? 'has-children' : ''"
            v-for="child in item.items"
            :key="child.title"
            :to="child.link"
        >
          <div class="w-100 d-flex align-center pa-2 border-radius-lg">
            <span class="v-list-item-mini" v-text="child.prefix"></span>

            <v-list-item-content class="ms-6 ps-1" v-if="!child.items">
              <v-list-item-title
                  @click="listClose($event)"
              >{{ child.title }}
              </v-list-item-title>
            </v-list-item-content>

            <v-list-item-content class="ms-6 ps-1 py-0" v-if="child.items">
              <v-list-group
                  prepend-icon=""
                  :ripple="false"
                  sub-group
                  no-action
                  v-model="child.active"
              >
                <template v-slot:activator>
                  <v-list nav dense class="pa-0">
                    <v-list-group
                        :ripple="false"
                        append-icon="fas fa-angle-down me-auto ms-1"
                        active-class="item-active"
                        class="mb-0"
                    >
                      <template v-slot:activator>
                        <v-list-item-content class="py-0">
                          <v-list-item-title
                          >{{ child.title }}
                          </v-list-item-title>
                        </v-list-item-content>
                      </template>
                    </v-list-group>
                  </v-list>
                </template>

                <v-list-item
                    v-for="child2 in child.items"
                    :ripple="false"
                    :key="child2.title"
                    :to="child2.link"
                    @click="listClose($event)"
                >
                  <span class="v-list-item-mini" v-text="child2.prefix"></span>
                  <v-list-item-content>
                    <v-list-item-title
                    >{{ child2.title }}
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list-group>
            </v-list-item-content>
          </div>
        </v-list-item>
      </v-list-group>

      <h5
          class="
          text-uppercase text-caption
          ls-0
          font-weight-bolder
          p-0
          mx-4
          mt-4
          mb-2
          ps-2
          d-none-mini
          white-space-nowrap
        "
          :class="sidebarTheme === 'dark' ? 'text-white' : 'text-default'"
      >
        <!-- Gestione -->
      </h5>

      <v-list-group
          :ripple="false"
          v-for="item in filteredItemsPages"
          :key="item.title"
          v-model="item.active"
          append-icon="fas fa-angle-down"
          class="pb-1 mx-2"
          active-class="item-active"
      >
        <template v-slot:activator>
          <v-list-item-icon class="me-2 align-center">
            <i class="material-icons-round opacity-10">{{ item.action }}</i>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title
                class="ms-1"
            >{{ item.title }}
            </v-list-item-title>
          </v-list-item-content>
        </template>

        <v-list-item
            :ripple="false"
            link
            class="mb-1 no-default-hover px-0"
            :class="child.items ? 'has-children' : ''"
            v-for="child in item.items"
            :key="child.title"
            :to="child.link"
        >
          <v-list-item-content class="ps-4" v-if="!child.items">
            <div class="d-flex align-items-center pa-2">
              <span class="v-list-item-mini ms-0" v-text="child.prefix"></span>
              <v-list-item-title
                  class="ms-6"
                  @click="listClose($event)"
              >{{ child.title }}
              </v-list-item-title>
            </div>
          </v-list-item-content>

          <v-list-item-content class="py-0" v-if="child.items">
            <v-list-group
                prepend-icon=""
                :ripple="false"
                sub-group
                no-action
                v-model="child.active"
            >
              <template v-slot:activator>
                <v-list nav dense class="py-2 ps-5 pe-2">
                  <v-list-group
                      :ripple="false"
                      append-icon="fas fa-angle-down me-auto ms-1"
                      active-class="item-active"
                      class="mb-0"
                  >
                    <template v-slot:activator>
                      <div class="w-100 d-flex align-center">
                        <span
                            class="v-list-item-mini ms-1"
                            v-text="child.prefix"
                        ></span>

                        <v-list-item-content class="py-0 ms-4">
                          <v-list-item-title
                              class="ms-2"
                          >{{ child.title }}
                          </v-list-item-title>
                        </v-list-item-content>
                      </div>
                    </template>
                  </v-list-group>
                </v-list>
              </template>

              <v-list-item
                  v-for="child2 in child.items"
                  :ripple="false"
                  :key="child2.title"
                  :to="child2.link"
                  @click="listClose($event)"
                  class="px-0"
              >
                <v-list-item-content>
                  <div class="d-flex align-items-center pa-2">
                    <span
                        class="v-list-item-mini"
                        v-text="child2.prefix"
                    ></span>
                    <v-list-item-title
                        class="ms-6"
                    >{{ child2.title }}
                    </v-list-item-title>
                  </div>
                </v-list-item-content>
              </v-list-item>
            </v-list-group>
          </v-list-item-content>
        </v-list-item>
      </v-list-group>

    </v-list>

    <v-card
        class="pa-0 border-radius-lg mt-7 mb-9 mx-4"
        :style="`background-image: url(${require('../assets/img/curved-images/white-curved.jpeg')}); background-size: cover;`"
    >
      <span
          class="mask opacity-8 border-radius-lg"
          :class="`bg-gradient-` + sidebarColor"
      ></span>
    </v-card>
  </v-navigation-drawer>
</template>
<script>
import { mapGetters } from 'vuex';

export default {
  name: "drawerMenu",
  props: {
    drawer: {
      type: Boolean,
      default: null,
    },
    sidebarColor: {
      type: String,
      default: "success",
    },
    sidebarTheme: {
      type: String,
      default: "dark",
    },
  },
  data: () => ({
    mini: false,
    togglerActive: false,
    thirdLevelSimple: [
      "Third level menu",
      "Just another link",
      "One last link",
    ],
    userInfo: [
      {
        title: "Mio Profilo",
        prefix: "MP",
      },
      {
        title: "Settings",
        prefix: "S",
      },
    ],
    items: [
      {
        action: "dashboard",
        active: true,
        title: "Dashboards",
        items: [
          // {
          //   title: "Statistiche Vini",
          //   prefix: "S",
          //   link: "/dashboards/analytics",
          // },
          {
            title: "Real Stats",
            prefix: "RS",
            link: "/dashboards/stats",
          },
        ],
      },
    ],
    itemsPages: [
      // ******************* VINI *******************
      {
        action: "wine_bar_icon",
        active: false,
        title: "Vini",
        items: [
          {
            title: "Elenco Vini",
            prefix: "E",
            link: "/elenco-vini",
          },
          {
            title: "Tipi di Vino",
            prefix: "T",
            link: "/tipi-vino",
          },
          {
            title: "Tipi di Uva",
            prefix: "U",
            link: "/tipi-uva",
          },
        ],
      },

      // ******************* TERRITORI *******************
      // {
      //   action: "public_icon",
      //   active: false,
      //   title: "Territori",
      //   items: [
      //     {
      //       title: "Nazioni",
      //       prefix: "N",
      //       link: "/pages/ecommerce/products/product-page",
      //     },
      //     {
      //       title: "Regioni",
      //       prefix: "R",
      //       link: "/pages/dashboards/sales",
      //     },
      //     {
      //       title: "Province",
      //       prefix: "P",
      //       link: "/pages/dashboards/sales",
      //     },
      //     {
      //       title: "Comuni",
      //       prefix: "C",
      //       link: "/pages/dashboards/sales",
      //     },
      //   ],
      // },

      // ******************* FORNITORI *******************
      {
        action: "liquor_icon",
        active: false,
        title: "Fornitori",
        items: [
          {
            title: "Produttori",
            prefix: "P",
            link: "/produttori",
          },
          {
            title: "Distributori",
            prefix: "D",
            link: "/distributori",
          },
        ],
      },

      // ******************* MAGAZZINI *******************
      {
        action: "warehouse",
        active: false,
        title: "Magazzini",
        items: [
          {
            title: "Elenco Magazzini",
            prefix: "EM",
            link: "/magazzini",
          },
          {
            title: "Elenco Snapshot",
            prefix: "ES",
            link: "/snapshot"
          }
        ],
      },

      // ******************* LISTINI *******************
      {
        action: "list_alt_icon",
        active: false,
        title: "Listini",
        items: [
          {
            title: "Gestione Listini",
            prefix: "GL",
            link: "/listini",
          },
        ],
      },

      // ******************* ORDINI *******************
      {
        action: "list",
        active: false,
        title: "Ordini",
        items: [
          {
            title: "Ordini d'Acquisto",
            prefix: "O",
            link: "/ordini"
          },
          {
            title: "Ricorda Ordini",
            prefix: "RO",
            link: "/ricorda-ordini"
          },
        ],
      },
      // ******************* CASSE **********************
      {
        action: "attach_money",
        active: false,
        title: "Casse",
        role: 'admin',
        items: [
          {
            title: "Lista Casse",
            prefix: "LC",
            link: "/casse"
          },
          {
            title: "Lista Scarichi",
            prefix: "LS",
            link: "/scarichi"
          },
        ],
      },
      // ******************* REPORTS *******************
      {
        action: "trending_up",
        active: false,
        title: "Reports",
        role: 'admin',
        items: [
          {
            title: "Fatturato",
            prefix: "F",
            link: "/reports/casse-fatturato"
          },
          // {
          //   title: "Statistiche",
          //   prefix: "ST",
          //   link: "/reports/statistiche"
          // },
          {
            title: "Analytics",
            prefix: "AV",
            link: "/reports/analytics"
          },
        ],
      },
      // ******************* SETTINGS *******************
      {
        action: "settings",
        active: false,
        title: "Settings",
        items: [
          {
            title: "Page Settings",
            prefix: "PS",
            link: "/settings"
            // link: "/pages/ecommerce/products/product-page",
          },
        ],
      },

      // {
      //   action: "logout",
      //   active: false,
      //   title: "Logout",
      //   items: [
      //     {
      //       title: "Logout",
      //       prefix: "L",
      //     },
      //   ],
      // },
    ],
  }),
  methods: {
    logout() {
    },
    listClose(event) {
      let items;
      let headers;
      let groups;
      let currentEl;
      if (
          document.querySelectorAll(
              ".mb-0.v-list-item.v-list-item--link.item-active"
          )
      ) {
        items = document.querySelectorAll(
            ".mb-0.v-list-item.v-list-item--link.item-active"
        );
      }

      if (
          document.querySelectorAll(
              ".mb-0.v-list-item.v-list-item--link .v-list-group__header.v-list-item--active"
          )
      ) {
        headers = document.querySelectorAll(
            ".mb-0.v-list-item.v-list-item--link .v-list-group__header.v-list-item--active"
        );
      }

      if (
          document.querySelectorAll(
              ".mb-0.v-list-item.v-list-item--link .v-list-group .v-list .v-list-group.v-list-group--active, .mb-0.v-list-item.v-list-item--link .v-list-group.v-list-group--active"
          )
      ) {
        groups = document.querySelectorAll(
            ".mb-0.v-list-item.v-list-item--link .v-list-group .v-list .v-list-group.v-list-group--active, .mb-0.v-list-item.v-list-item--link .v-list-group.v-list-group--active"
        );
      }

      if (
          event.target.closest(
              ".mb-0.v-list-item.v-list-item--link .v-list-item__content.ms-6 .v-list-group"
          )
      ) {
        currentEl = event.target.closest(
            ".mb-0.v-list-item.v-list-item--link .v-list-item__content.ms-6 .v-list-group"
        );
      }

      if (items != null) {
        for (let i = 0; i < items.length; i++) {
          items[i].classList.remove("item-active");
        }
      }

      if (headers != null) {
        for (let j = 0; j < headers.length; j++) {
          headers[j].classList.remove(
              "v-list-item--active",
              "item-active",
              "primary--text"
          );
          headers[j].setAttribute("aria-expanded", false);
        }
      }

      if (groups != null) {
        for (let k = 0; k < groups.length; k++) {
          groups[k].classList.remove("v-list-group--active", "primary--text");
        }
      }

      if (event.target.closest(".mb-0.v-list-item.v-list-item--link")) {
        event.target
            .closest(".mb-0.v-list-item.v-list-item--link")
            .classList.add("item-active");
      }

      if (currentEl != null) {
        currentEl
            .querySelector(".v-list-group__header")
            .classList.add("v-list-item--active", "item-active");
      }
    },
  },
  computed: {
    ...mapGetters({
      userRole: 'auth/userRole' // Assicurati che il percorso sia corretto in base a come hai configurato il tuo store Vuex
    }),
    logoImageSrc() {
    // Qui potresti aggiungere una logica condizionale se il percorso dell'immagine cambia in base al tema.
    // Al momento, ritorna semplicemente il percorso fisso poiché è lo stesso in entrambi i casi nel tuo esempio.
    return "@/assets/img/logo-vv.png";
  },
  filteredItemsPages() {
    return this.itemsPages.filter(item => {
      // Se non è definito un ruolo per l'item, oppure se il ruolo corrisponde a quello dell'utente, includi l'item
      return !item.role || item.role === this.userRole;
    });
  }
}
};
</script>