var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-overlay',{attrs:{"value":_vm.progress}},[_c('v-progress-circular',{attrs:{"indeterminate":"","size":70,"width":7,"color":"purple"}})],1),_c('v-card',{staticClass:"card-shadow border-radius-xl",attrs:{"elevation":"5"}},[_c('div',{staticClass:"card-header-padding"},[_c('div',{staticClass:"d-flex inline font-weight-bold text-h4 text-typo mb-0"},[_vm._v(" Elenco Scarichi "),_c('v-chip',{staticClass:"ma-2",attrs:{"color":"green","outlined":""}},[_vm._v(" Totale Scarichi: "+_vm._s(_vm.totale_scarichi)+" ")]),_c('v-spacer'),_c('v-btn',{attrs:{"color":"pink","dark":""},on:{"click":_vm.resetFiltri}},[_vm._v("Reset Filtri")])],1)]),_c('v-card-text',[_c('div',{},[_c('v-row',[_c('v-col',{attrs:{"cols":"5"}},[_c('v-text-field',{staticClass:"text-pink",attrs:{"clearable":"","label":"Cerca Vino","type":"search"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-col',{attrs:{"align":"center","cols":"2"}},[_c('v-btn',{attrs:{"color":"pink","dark":""},on:{"click":_vm.resetFiltri}},[_vm._v("Reset Filtri")])],1),_c('v-col',{attrs:{"cols":"5"}},[_c('label',[_vm._v("Range Date")]),_c('br'),_c('date-range-picker',{ref:"picker",staticClass:"custom_date_range",attrs:{"date-format":_vm.dateFormat,"auto-apply":true,"timePicker":false,"locale":_vm.localeData},model:{value:(_vm.dateRange),callback:function ($$v) {_vm.dateRange=$$v},expression:"dateRange"}}),_vm._v(" "+_vm._s(_vm.date)+" ")],1)],1)],1),_c('v-data-table',{attrs:{"items":_vm.scarichi,"headers":_vm.headers},scopedSlots:_vm._u([{key:"item.created_at",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatDate")(item.created_at))+" ")]}},{key:"item.download_name",fn:function(ref){
var item = ref.item;
return [(item.download_name === 'Mescita')?_c('span',{staticClass:"text-pink"},[_vm._v(" "+_vm._s(item.download_name)+" ")]):_c('span',{staticClass:"text-indigo"},[_vm._v(" "+_vm._s(item.download_name)+" ")])]}},{key:"item.cell_name",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[_vm._v(_vm._s(item.cell_name))])]}}],null,true)},[_c('span',[_vm._v(_vm._s(item.cell))])])]}}],null,true)})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }