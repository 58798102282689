const getDefaultState = () => {
  return {
    winemakers: [],
    background: null,
    hasBg: true,
    linkColor: "",
    toggleActive: true,
    navbarFixed: {
      type: true,
      default: false,
    }
  }}

export const settings = {
  namespaced: true,
  state: {
    setting : getDefaultState()
  },

  actions: {
    setColor ({ commit }, color) {
      commit('SETCOLOR', color)
    },
    setSidebar({ commit }, color) {
      commit('SETSIDEBAR',color)
    },
    setDistributorId({ commit }, id) {
      commit('SETDISTRIBUTORID', id)
    },
    setWinemakers({ commit }, winemakers ) {
      commit('SETWINEMAKERS', winemakers)
    }

  },
  mutations: {
    SETCOLOR(state, color) {
      state.setting.linkColor = color;
    },
    SETSIDEBAR(state, color) {
      state.setting.background = color
    },
    SETDISTRIBUTORID(state, id) {
      state.setting.distributorId = id
    },
    SETWINEMAKERS(state, winemakers) {
      state.setting.winemakers = winemakers
    }

  }
}
