import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import vuetify from "./plugins/vuetify";
import DashboardPlugin from "./plugins/dashboard-plugin";
import store from "@/store";
import VuetifyDialog from 'vuetify-dialog'
import 'vuetify-dialog/dist/vuetify-dialog.css'
import '@mdi/font/css/materialdesignicons.css' // Ensure you are using css-loader
import Vuetify from 'vuetify/lib'
import VueHtmlToPaper from 'vue-html-to-paper';
import FlagIcon from 'vue-flag-icon'
import QrcodeVue from 'qrcode.vue'
import VueQrPrint from 'vue-qr-print'
import VueQRCodeComponent from 'vue-qrcode-component'
import moment from 'moment';
import VueLodash from 'vue-lodash'
import lodash from 'lodash'
import * as VueGoogleMaps from 'vue2-google-maps'
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css'

Vue.use(VueLodash, { name: 'custom' , lodash: lodash })
Vue.config.productionTip = false
Vue.filter('formatDate', function(value) {
    if (value) {
        return moment(String(value)).format('DD/MM/YYYY')
    }
});
Vue.filter('formatDateHour', function(value) {
    if (value) {
        return moment(String(value)).format('DD/MM/YYYY HH:MM:SS')
    }
});
Vue.filter('toCurrency', function (value) {
    if (typeof value !== "number") {
        return value;
    }
  const formatter = new Intl.NumberFormat('it-IT', {
    style: 'currency',
    currency: 'EUR'
  });
  return formatter.format(value);
});
Vue.component('qr-code', VueQRCodeComponent)
Vue.use(VueGoogleMaps, {
  load: {
    key: 'AIzaSyAX5CDkFKyK9cfNQgdNUTGCmV1iNJkZ6Mk',
    libraries: 'places', // Questo è necessario se vuoi usare il Places API
  }
})
Vue.use(VueQrPrint);
Vue.use(QrcodeVue)

Vue.use(FlagIcon);
const options = {
  name: '_blank',
  specs: [
    'fullscreen=yes',
    'titlebar=yes',
    'scrollbars=yes'
  ],
  styles: [],
  timeout: 1000, // default timeout before the print window appears
  autoClose: true, // if false, the window will not close after printing
  windowTitle: window.document.title, // override the window title
}
Vue.use(VueHtmlToPaper, options);


Vue.config.productionTip = false;

// Photoswipe Gallery
import Photoswipe from "vue-pswipe";
Vue.use(Photoswipe);
Vue.use(Vuetify)

// plugin setup
Vue.use(DashboardPlugin);
Vue.use(VuetifyDialog, {
  context: {
    vuetify,
  },
})
export default new Vuetify({
  icons: {
    iconfont: ['fa','mdi', 'md'] // default - only for display purposes
  },
})

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
