import axios from 'axios';
import authHeader from '@/services/auth.header';

const API_URL = process.env.VUE_APP_API_URL;


class UserService {
  getUser(params){
    return axios.get(API_URL + 'accounts/', { headers: authHeader(), params:params})
  }
  getSetUser(id) {
    return axios.get(API_URL + 'accounts/' + id + '/', {headers: authHeader()});
  }

}

export default new UserService();
