<template>
<div>
    <v-overlay :value="progress">
      <v-progress-circular indeterminate :size="70" :width="7" color="purple">
      </v-progress-circular>
    </v-overlay>
    <v-card class="card-shadow border-radius-xl" elevation="5">
      <div class="card-header-padding">
        <div class="font-weight-bold text-h4 text-typo mb-0">
          Pagina Report delle Casse
          <v-chip class="ma-2" color="green" outlined>
            Totale Casse:
          </v-chip>
        </div>
      </div>
        <v-card-text>
            <v-card>
                <v-card-text>
                    <v-card class="mb-3 card-shadow border-radius-xl py-4">
                        <v-row no-gutters class="px-4">
                            <v-col sm="4">
                                <v-avatar
                                        color="bg-gradient-info shadow border-radius-xl mt-n8"
                                        class="shadow-dark"
                                        height="64"
                                        width="64"
                                >
                                    <v-icon class="material-icons-round text-white" size="24">
                                        query_stats
                                    </v-icon>
                                </v-avatar>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col>
                                <v-data-table
                                        :headers="headers"
                                        :items="casse"
                                        small
                                >
                                    <template v-slot:[`item.data`]="{ item }">
                                        <span>{{ item.data | formatDate }}</span>
                                    </template>
                                </v-data-table>
                            </v-col>
                        </v-row>
                    </v-card>
                </v-card-text>
            </v-card>
        </v-card-text>
    </v-card>
</div>
</template>

<script>
import ReportService from "@/services/report.service";
export default {
    name: "CassaView",
    data() {
        return {
            progress: true,
            casse: [],
            headers: [
                { text: 'Store', value: 'store_name' },
                { text: 'Data', value: 'data' },
                { text: 'Chiusura 15', value: 'chiusura15' },
                { text: 'Chiusura 17', value: 'chiusura17' },
                { text: 'Incasso', value: 'incasso' },
                { text: 'Total Price Cost', value: 'total_price_cost' },
                { text: 'Removed Quantity', value: 'removed_quantity' },
                { text: 'Price Total Reduce', value: 'price_total_reduce' },
            ],
            cards: [
                {
                    icon: "account_circle",
                    text: "Users Active",
                    value: "1,600",
                    percent: "+55%",
                },
                {
                    icon: "pan_tool",
                    text: "Click Events",
                    value: "357",
                    percent: "+124%",
                },
                {
                    icon: "shopping_basket",
                    text: "Purchases",
                    value: "2,300",
                    percent: "+15%",
                },
                {
                    icon: "thumb_up",
                    text: "Likes",
                    value: "940",
                    percent: "+90%",
                },
            ],
        }
    },
    mounted() {
        this.initReportCasse()
    },
    methods: {
        initReportCasse() {
            ReportService.getReportCasse().then(resp => {
                this.casse = resp.data.results
                this.progress = false
            }).catch()
        }
    }
}
</script>

<style scoped>

</style>