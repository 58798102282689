import axios from "axios";
import authHeader from "@/services/auth.header";

const API_URL = process.env.VUE_APP_API_URL;

class CasseService {
    async getCasse(params) {
        return (
            await axios
                .get(API_URL + 'casse/', {
                    headers: authHeader(), params: params
                })
        );
    }

    async putCasse(payload, params) {
        return await axios.put(API_URL + `casse/${payload.id}/`, payload, {headers: authHeader(), params:params})
    }

    async postCassa(payload) {
        return await axios.post(API_URL + 'casse/', payload, {headers: authHeader()})
    }

    async detailCasse(params) {
        return await axios.get(API_URL + 'casse/retrieve_bottle_sell', {
            headers: authHeader(), params: params
        })
    }

    async delete(id, params) {
        return await axios.delete(API_URL + `casse/${id}/`, { headers: authHeader(), params:params })
    }
}

export default new CasseService()
