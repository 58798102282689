import AuthService from '../services/auth.service';
import UserService from '@/services/user.service';
const getDefaultState = () => {
  return {
    account: null,
    user: null,
    loggedIn: false,
    store: null,
    token: null,
    error: null,
    userRole: null,
    status: {
      loggedIn: false
    },
    staff: {}
  }
}
// const user = JSON.parse(localStorage.getItem('user'))
// const initialState = user
//   ? { status: { loggedIn: true }, user }
//   : { status: { loggedIn: false }, user: null };

export const auth = {
  namespaced: true,
  state: getDefaultState(),
  getters: {
    // Getter per verificare se l'utente è loggato
    isLoggedIn: (state) => {
      return state.loggedIn;
    },
    // Getter per ottenere l'oggetto utente
    user: (state) => {
      return state.user;
    },
    // Getter per ottenere il ruolo dell'utente
    userRole: (state) => {
      return state.userRole;
    },
    // Getter per ottenere il token di autenticazione
    authToken: (state) => {
      return state.token;
    },
    // Getter per ottenere eventuali errori di autenticazione
    authError: (state) => {
      return state.error;
    },
    // Getter per ottenere lo stato di "logged in" con dettagli
    authStatus: (state) => {
      return state.status;
    },
    // Getter per ottenere informazioni sull'account
    accountInfo: (state) => {
      return state.account;
    }
  },
  actions: {
    login({ commit, dispatch }, user) {
      return AuthService.login(user).then(
        user => {
          commit('loginSuccess', user);
          dispatch('setAccount')
          return Promise.resolve(user);
        },
        error => {
          commit('loginFailure', error);
          return Promise.reject(error);
        }
      );
    },
    setAccount(context){
      let id = context.state.user.user_id
      UserService.getSetUser(id).then(
        response => {
          context.commit('setAccountInState', response.data)
        }
      )
    },
    logout({ commit }) {
      AuthService.logout()
      commit('logout');
    },
    register({ commit }, user) {
      return AuthService.register(user).then(
        response => {
          commit('registerSuccess');
          return Promise.resolve(response.data);
        },
        error => {
          commit('registerFailure');
          return Promise.reject(error);
        }
      );
    }
  },
  mutations: {
    loginSuccess(state, user) {
      state.loggedIn = true;
      state.token = user.token
      state.user = user;
    },
    loginFailure(state, error) {
      state.loggedIn = false;
      state.user = null;
      state.error = error;
    },
    logout(state) {
      localStorage.clear()
      const cookies = document.cookie.split(";");
      Object.assign(state, getDefaultState)
      for (let i = 0; i < cookies.length; i++) {
        const cookie = cookies[i];
        const eqPos = cookie.indexOf("=");
        const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
        document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
      }
    },
    registerSuccess(state) {
      state.loggedIn = false;
    },
    registerFailure(state) {
      state.loggedIn = false;
    },
    setAccountInState(state, account) {
      state.account = account
      state.userRole = account.is_staff ? "admin":"user"
      localStorage.setItem('account', JSON.stringify(account))
    }
  }
};
