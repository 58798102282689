import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from "vuex-persistedstate"
import { auth } from '@/store/auth'
import { settings } from '@/store/settings'
// import { listini } from '@/store/listini'

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        user:[],
        account:{},
    },
    getters: {},
    mutations: {},
    actions: {},
    modules: {
      auth: auth,
      settings: settings,
      // listini: listini,
    },
  plugins:[ createPersistedState() ]
})
