import Vue from "vue";
import VueRouter from "vue-router";
import DashboardLayout from "../views/Layout/DashboardLayout.vue";
import AuthBasicLayout from "../views/Layout/AuthBasicLayout";
import SettingsView from "../views/Pages/Settings/SettingsView.vue"
import ReportsView from "@/views/Pages/Reports/ReportsView.vue";


// const Discover = () => import("../views/Dashboard/Discover.vue");
// const Automotive = () => import("../views/Dashboard/Automotive.vue");
// const Sales = () => import("../views/Dashboard/Sales.vue");
// const SmartHome = () => import("../views/Dashboard/SmartHome.vue");
// const Crm = () => import("../views/Dashboard/Crm.vue");
// const GettingStarted = () => import("../views/Dashboard/GettingStarted.vue");


// MIE PAGINE

// Dashboard pages
const Dashboard = () => 
    import("../views/Dashboard/Dashboard.vue");
const Dashboard2 = () => 
    import("../views/Dashboard/Dashboard2.vue");

// Vini
const Vini = () =>
    import(/* webpackChunkName: "pages" */ "@/views/Pages/Vini/ElencoVini.vue");
const TipiVino = () =>
    import(/* webpackChunkName: "pages" */ "@/views/Pages/TypeAndGrapes/TipiVino.vue");
const TipiUva = () =>
    import(/* webpackChunkName: "pages" */ "@/views/Pages/TypeAndGrapes/TipiUva.vue");

// Fornitori
const Produttori = () =>
    import(/* webpackChunkName: "pages" */ "@/views/Pages/Winemaker/ProduttoriPage.vue");
const Distributori = () =>
    import(/* webpackChunkName: "pages" */ "@/views/Pages/Winemaker/DistributoriPage.vue");

// Listini
const ListiniView = () =>
    import(/* webpackChunkName: "pages" */ "@/views/Pages/Listini/ListiniView.vue");

// Ordini
const OrdiniView = () =>
    import(/* webpackChunkName: "pages" */ "@/views/Pages/Ordini/OrdiniView.vue");
const RicordaOrdiniView = () =>
    import(/* webpackChunkName: "pages" */ "@/views/Pages/Ordini/RicordaOrdiniView.vue");

// Magazzini
const ListWareHouse = () =>
    import(/* webpackChunkName: "pages" */ "@/views/Pages/WareHouse/ListWareHouse.vue");
const ListSnapshot = () =>
    import("@/views/Pages/WareHouse/ElencoSnapshot.vue")
// Casse
const ListCasse = () =>
    import(/* webpackChunkName: "pages" */ "@/views/Pages/Casse/CassaView.vue");

const Reports = () =>
    import(/* webpackChunkName: "pages" */ "@/views/Pages/Users/Reports.vue");
//
const Stats = () =>
    import("@/views/Pages/Reports/StatsView.vue");

const Analytics = () => 
    import("@/views/Pages/Reports/AnalyticsView.vue");

const SignInBasic = () =>
    import(
        /* webpackChunkName: "pages" */ "@/views/Pages/Authentication/SignIn/Basic.vue"
        );

Vue.use(VueRouter);


// MIE PAGINE
let dashboards = {
    path: '/',
    component: DashboardLayout,
    name: "dashboard",
    children: [
        {
            path: "dashboards/analytics",
            name: "analytics",
            component: Dashboard,
            meta: {
                groupName: "Dashboard",
            },
        },
        {
            path: "dashboards/stats",
            name: "stats",
            component: Dashboard2,
            meta: {
                groupName: "Dashboard",
            },
        }
    ]
};

let elencoVini = {
    path: "/",
    component: DashboardLayout,
    name: "ElencoVini",
    children: [
        {
            path: "elenco-vini",
            name: "Vini",
            component: Vini,
            meta: {
                groupName: "Vini",
            },
        },
    ],
};

let tipiVino = {
    path: "/",
    component: DashboardLayout,
    name: "TipiVino",
    children: [
        {
            path: "tipi-vino",
            name: "Tipi di Vino",
            component: TipiVino,
            meta: {
                groupName: "Vini",
            },
        },
    ],
};

let tipiUva = {
    path: "/",
    component: DashboardLayout,
    name: "TipiUva",
    children: [
        {
            path: "tipi-uva",
            name: "Tipi di Uva",
            component: TipiUva,
            meta: {
                groupName: "Uva",
            },
        },
    ],
};

let magazzini = {
    path: "/",
    component: DashboardLayout,
    children: [
        {
            path: "magazzini",
            name: "Magazzini",
            component: ListWareHouse,
            meta: {
                groupName: "Magazzini",
            },
        },
        {
            path: "snapshot",
            name: "Snapshot",
            component: ListSnapshot,
            meta: {
                groupName: "Snapshot"
            }
        }
    ],
}

let casse = {
    path: "/",
    component: DashboardLayout,
    children: [
        {
            path: "casse",
            name: "Casse",
            component: ListCasse,
            meta: {
                groupName: "Casse",
                requiresAuth: true,
                roles: ['admin']
            },
        },
        {
            path: "scarichi",
            name: "Scarichi",
            component: ScarichiView,
            meta: {
                groupName: "Casse",
            },
        },
    ],
}

let produttori = {
    path: "/",
    component: DashboardLayout,
    name: "Winemakers",
    children: [
        {
            path: "produttori",
            name: "Produttori",
            component: Produttori,
            meta: {
                groupName: "Produttori",
            },
        },
    ],
};

let distributori = {
    path: "/",
    component: DashboardLayout,
    name: "Distributors",
    children: [
        {
            path: "distributori",
            name: "Distributori",
            component: Distributori,
            meta: {
                groupName: "Distributori",
            },
        },
    ],
};

let listini = {
    path: "/",
    component: DashboardLayout,
    name: "Pricelists",
    children: [
        {
            path: "listini",
            name: "Listini",
            component: ListiniView,
            meta: {
                groupName: "Listini",
            },
        },
    ],
};

let ordini = {
    path: "/",
    component: DashboardLayout,
    name: "Order",
    children: [
        {
            path: "ordini",
            name: "Ordini",
            component: OrdiniView,
            meta: {
                groupName: "Ordini",
            },
        },
        {
            path: "ricorda-ordini",
            name: "Ricorda Ordini",
            component: RicordaOrdiniView,
            meta: {
                groupName: "Ordini",
            },
        },
    ],
};

let settings = {
    path: "/",
    component: DashboardLayout,
    children: [
        {
            path: "settings",
            name: "Settings",
            component: SettingsView,
            meta: {
                groupName: "Settings",
            },
        },
    ],
};

let reports = {
    path: "/reports",
    component: DashboardLayout,
    children: [
        {
            path: "/reports/general",
            name: "Reports",
            component: Reports,
            meta: {
                groupName: "Reports",
            },
        },
        {
            path: "/reports/casse",
            name: "Report Casse",
            component: ReportsView,
            meta: {
                groupName: "Reports",
                requiresAuth: true,
                roles: ['admin'],
            },
        },
        {
            path: "/reports/casse-fatturato",
            name: "Fatturato",
            component: ReportFatturato,
            meta: {
                groupName: "Reports",
                requiresAuth: true,
                roles: ['admin'],
            },
        },
        {
            path: "/reports/statistiche",
            name: "Statistiche",
            component: Stats,
            meta: {
                groupName: "Reports"
            }
        },
        {
            path: "/reports/analytics",
            name: "Analytics",
            component: Analytics,
            meta: {
                groupName: "Reports"
            }
        }
    ],
};

let authBasicSignIn = {
    path: "/",
    component: AuthBasicLayout,
    name: "Authentication Basic SignIn",
    children: [
        {
            path: "/pages/authentication/signin/basic",
            name: "SignInBasic",
            component: SignInBasic,
        },
    ],
};


const routes = [
    {
        path: "/login",
        name: "Login",
        redirect: '/pages/authentication/signin/basic',
        component: SignInBasic
    },
    {
        path: "/",
        name: "Dashboard",
        component: DashboardLayout,
    },
    dashboards,
    settings,
    ordini,
    listini,
    distributori,
    produttori,
    tipiUva,
    tipiVino,
    elencoVini,
    magazzini,
    casse,
    reports,
    authBasicSignIn,
];
import store from '@/store/index.js';
import ReportFatturato from "@/views/Pages/Reports/ReportFatturato.vue";
import ScarichiView from "@/views/Pages/Casse/ScarichiView.vue";


const router = new VueRouter({
    mode: 'history',
    routes,
});
export default router;


// router.beforeEach((to, from, next) => {
//     if (to.name !== 'SignInBasic' && !store.state.auth.loggedIn) {
//         next('/login')
//     } else {
//         next()
//     }
// })

router.beforeEach((to, from, next) => {
    const isLoggedIn = store.state.auth.loggedIn; // Controlla se l'utente è loggato
    const userRole = store.state.auth.userRole; // Assumi che il ruolo dell'utente sia memorizzato qui
    if (to.name !== 'SignInBasic' && !isLoggedIn) {
        // Se l'utente non è loggato e sta tentando di accedere a una pagina diversa da SignInBasic, reindirizza al login
        next('/login');
    } else if (to.matched.some(record => record.meta.requiresAuth && record.meta.roles && !record.meta.roles.includes(userRole))) {
        next('/access-denied');
    } else {
        next();
    }
});