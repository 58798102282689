import axios from "axios";
import authHeader from "@/services/auth.header";
const API_URL = process.env.VUE_APP_API_URL;

class ReportService {
  async getReportCasse() {
    return await axios.get(API_URL + 'report_cassa', {
              headers: authHeader()
    })
  }
  async getBestSeller() {
      return await axios.get(API_URL + 'report_bistrot', {
          headers: authHeader()
      })
  }
  async getFatturatoLastWeek() {
      return await axios.get(API_URL + 'report_cassa/fatturatoSettimanale/', {
          headers: authHeader()
      })
  }
  async getReportTotali(params) {
      return await axios.get(API_URL + 'report_totali', {
          headers:authHeader(),
          params:params
      })
  }
  async getReportMensili(params) {
      return await axios.get(API_URL + 'report_mensili', {
          headers:authHeader(),
          params:params
      })
  }
  async getReportMensiliDetails(params){
      return await axios.get(API_URL + 'report_mensili_details/', {
          headers:authHeader(), params:params
      })
  }
  async getReportCustomData(params) {
      return await axios.get(API_URL + "report_custom_data", { headers:authHeader(), params: params } )
  }
}

export default new ReportService()
