<template>
  <div>
    <v-overlay :value="progress">
      <v-progress-circular indeterminate :size="70" :width="7" color="purple">
      </v-progress-circular>
    </v-overlay>
    <v-card class="card-shadow border-radius-xl" elevation="5">
      <div class="card-header-padding">
        <div class="d-flex inline font-weight-bold text-h4 text-typo mb-0">
          Elenco Scarichi
          <v-chip class="ma-2" color="green" outlined>
            Totale Scarichi: {{ totale_scarichi }}
          </v-chip>
          <v-spacer></v-spacer>
          <v-btn color="pink" dark @click="resetFiltri">Reset Filtri</v-btn>
        </div>
      </div>
      <v-card-text>
        <div class="">
          <v-row>
            <v-col cols="5">
              <v-text-field
                  clearable
                  v-model="search"
                  label="Cerca Vino"
                  type="search"
                  class="text-pink"
              ></v-text-field>
            </v-col>
            <v-col align="center" cols="2">
              <v-btn color="pink" dark @click="resetFiltri">Reset Filtri</v-btn>
            </v-col>
            <v-col cols="5">
              <label>Range Date</label><br>
              <date-range-picker
                  ref="picker"
                  :date-format="dateFormat"
                  v-model="dateRange"
                  class="custom_date_range"
                  :auto-apply="true"
                  :timePicker="false"
                  :locale="localeData"
              >

              </date-range-picker>
              {{ date }}
            </v-col>
          </v-row>
        </div>
        <v-data-table
            :items="scarichi"
            :headers="headers"
        >
          <template v-slot:[`item.created_at`]="{ item }">
            {{ item.created_at | formatDate }}
          </template>
          <template v-slot:[`item.download_name`]="{ item }">
            <span v-if="item.download_name === 'Mescita'" class="text-pink">
              {{ item.download_name }}
            </span>
            <span v-else class="text-indigo">
              {{ item.download_name }}
            </span>
          </template>
          <template v-slot:[`item.cell_name`]="{ item }">
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <span v-bind="attrs" v-on="on">{{ item.cell_name }}</span>
              </template>
              <span>{{ item.cell }}</span>
            </v-tooltip>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
  </div>
</template>
<script>
import WarehouseService from "@/services/warehouse.service";
import moment from "moment";
import DateRangePicker from 'vue2-daterange-picker'
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css'
export default {
  components: {
    DateRangePicker
  },
  data() {
    return {
      progress: false,
      totale_scarichi: 0,
      scarichi: [],
      headers: [
        {value: "id", text: "ID"},
        {value: "created_at", text: "Data", width: "160px"},
        {value: "download_name", text: "Tipo"},
        {value: "rack_name", text: "Rack"},
        {value: "cell_name", text: "Cella"},
        {value: "wine_name", text: "Vino", width: "380px"},
        {value: "winemaker_name", text: "Produttore", width: "250px"},
        {value: "removed_quantity", text: "Rimosse"},
        {value: "old_quantity", text: "Q.tà Preced."},
        {value: "giacenza.quantity_tot", text: "Giacenza"},
      ],
      date: "", // (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
      menu: false,
      search: null,
      dateRange: {
          startDate: moment().format('YYYY-MM-DD'),
          endDate: moment().format('YYYY-MM-DD'),
      },
      localeData: {
          format: 'DD/MM/YYYY',
          separator: ' al ',
          daysOfWeek: ['Dom', 'Lun', 'Mar', 'Mer', 'Gio', 'Ven', 'Sab'],
          monthNames: ['Gennaio', 'Febbraio', 'Marzo', 'Aprile', 'Maggio', 'Giugno', 'Luglio', 'Agosto', 'Settembre', 'Ottobre', 'Novembre', 'Dicembre'],
          applyLabel: 'Applica',
          cancelLabel: 'Annulla',
          fromLabel: 'Da',
          toLabel: 'A',
          firstDay: 1
    },
    }
  },
  filters: {
    formatDate(value) {
      if (!value) return '';
      const date = new Date(value);
      const day = date.getDate().toString().padStart(2, '0');
      const month = (date.getMonth() + 1).toString().padStart(2, '0'); // getMonth() restituisce mesi da 0 (gennaio) a 11 (dicembre)
      const year = date.getFullYear().toString().substring(2);
      const hours = date.getHours().toString().padStart(2, '0');
      const minutes = date.getMinutes().toString().padStart(2, '0');

      // Formatta la data nel nuovo formato
      return `${day}/${month}/${year} ${hours}:${minutes}`;
    }
  },
  watch: {
    dateRange(val) {
      this.initScarichi()
      console.log(val)
      // if (val) {
      //   this.progress = true
      //   const params = {
      //     by_data: val,
      //     store: this.$store.state.auth.user.store_id,
      //     by_wine_name: this.search || '',
      //     page_size: 250
      //   }
      //   WarehouseService.getSimpleBistro(params).then(resp => {
      //     this.totale_scarichi = resp.data.count
      //     this.scarichi = resp.data.results
      //   }).catch().finally(() => this.progress = false)
      // }
    },
    search(val) {
      if (val.length >= 3) {
        this.progress = true
        const params = {
          search: val,
          store: this.$store.state.auth.user.store_id,
          page_size: 250
        }
        WarehouseService.getSimpleBistro(params).then(resp => {
          this.scarichi = resp.data.results
          this.totale_scarichi = resp.data.count
        }).catch().finally(() => this.progress = false)
      } else if (val.length === 0 || null) {
        this.initScarichi()
      }
    }
  },
  created() {
    // this.initScarichi()
  },
  computed: {
    computedDateFormatted() {
      return this.date ? moment(this.date).locale('it').format('dddd, DD MMMM YYYY') : ''
    }
  },
  methods: {
    dateFormat (classes, date) {
        if (!classes.disabled) {
          classes.disabled = date.getTime() > new Date()
        }
        return classes
      },
    resetFiltri() {
      this.search = ""
      this.date = ""
      this.dateRange = {}
      this.initScarichi()
    },
    initScarichi() {
      this.progress = true
      const params = {
        store: this.$store.state.auth.user.store_id,
        page_size: 500,
        by_start_date:this.dateRange.startDate.toISOString(),
        by_end_date:this.dateRange.endDate.toISOString(),
      }
      WarehouseService.getSimpleBistro(params).then(resp => {
        this.totale_scarichi = resp.data.count
        this.scarichi = resp.data.results
        this.progress = false
      }).catch().finally()
    }
  }
}
</script>
<style scoped>
.custom_date_range {
  min-height: 40px;
  min-width: 300px;
  border: 0;
}
</style>