export default function authHeader() {
  const user = JSON.parse(localStorage.getItem("user"));
  const token = user.token

  if (user && token) {
    return {
      Authorization: "Token " + token,
    };
  }
  else {
      this.$router.push('/login')
          .then(r => console.log(r))
  }
}
